// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHbcFARfOxe2JIVXPcY_uUtmH2xpXrNbc",
  authDomain: "dailyblog-233cf.firebaseapp.com",
  projectId: "dailyblog-233cf",
  storageBucket: "dailyblog-233cf.appspot.com",
  messagingSenderId: "993170441938",
  appId: "1:993170441938:web:7269e168cd5343003bcf0b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const projectAuth = getAuth();
const projectFirestore = getFirestore();
const storage = getStorage();
const analytics = getAnalytics(app);

export { projectFirestore, projectAuth, storage };